<template>
  <div class="container">
    <div class="explain">
      <img src="../../../assets/optimizeImgs/warn.png" alt />
      <span>双击单元进行权限分配或取消</span>
      <span>（操作说明：打“√”代表全集团公司，否则本部门；编辑权限：打“√”代表有编辑权限，否则只有查阅权限）</span>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      height="calc(100% - 104px)"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="compName" label="公司">
        <template slot-scope="scope">
          <div style="text-align: left;">{{ scope.row.compName }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="deptName" label="部门" ></el-table-column>
      <el-table-column prop="userName" label="姓名" width="150"></el-table-column>
      <el-table-column prop="account" label="账号" width="150"></el-table-column>
      <el-table-column prop="postName" label="岗位"></el-table-column>
      <el-table-column prop="operation" label="全公司操作(双击单元)" width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.operation" placeholder="请双击单元格"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="jurisdiction" label="编辑权限(双击单元)" width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.jurisdiction" placeholder="请双击单元格"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      tableData: [
        {
          compName: "股份公司",
          operation: "",
          jurisdiction: "",
        },
        {
          compName: "股份公司",
          operation: "",
          jurisdiction: "",
        },
        {
          compName: "股份公司",
          operation: "",
          jurisdiction: "",
        },
        {
          compName: "股份公司",
          operation: "",
          jurisdiction: "",
        },
      ],
      page: 1,
      limit: 10,
      total: 0,
    };
  },
  created() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "../../../assets/style/optimizeTable.less";
.container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  .explain {
    height: 56px;
    padding-left: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    span:nth-of-type(1) {
      margin-left: 5px;
    }
    span:nth-of-type(2) {
      color: #f23737;
    }
  }
}
</style>